import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Spinner,
  StylesProvider,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'
import ReactMarkdown from 'react-markdown'

import {supabase} from '@/api'
import {JobListing} from '@/api/models'
import {transformComponents} from '@/common/markdown'
import Potato from './potato'

const Home = () => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [listings, setListings] = React.useState<JobListing[]>([])
  const loadJobs = React.useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const {data, error} = await supabase
        .from<JobListing>('job_listings')
        .select('*')
        .order('order', {ascending: true})
      if (error) {
        throw error
      }
      setListings(data || [])
    } catch (e) {
      setError(e.details || e.toString())
    } finally {
      setLoading(false)
    }
  }, [setLoading])
  React.useEffect(() => {
    loadJobs()
  }, [loadJobs])

  const colSpan = useBreakpointValue({base: 2, xl: 1})

  return (
    <Container maxW="container.xl" mb={8} px={8}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={useBreakpointValue({base: 4, lg: 3})} mb={8}>
          <Text mb={4} fontSize="2xl">
            We're actively looking for new engineers for our upcoming projects!
          </Text>

          <Text mb={4}>
            Every role is primarily a freelance arrangement, billed using your preferred method.
            Internationally, we support civil contracts, EU VAT invoices and other types of invoices. In
            Poland, we support B2B non-VAT, B2B EU VAT through a non-Polish company and civil contracts (umowa
            zlecenia, umowa o dzieło).
          </Text>

          <Text mb={4}>
            We take pride in being a no-bullshit place to work, with arrangements that resemble a loose
            cooperative of engineers, rather than a company that intends to extract maximized value from their
            employees.
          </Text>

          <Text>
            If you'd like to apply, please prepare an English resume, a short cover letter and send it to{' '}
            <Link href="mailto:jobs@pyr.sh">jobs@pyr.sh</Link>.
          </Text>
        </GridItem>
        <GridItem colSpan={useBreakpointValue({base: 4, lg: 1})} mb={8}>
          <Flex direction="row" alignItems="center" justifyContent="center" overflow="hidden" height="200px">
            <Box height="220px">
              <Potato />
            </Box>
          </Flex>
        </GridItem>
      </Grid>

      <Divider mb={8} />

      <Heading size="lg" mb={8}>
        Open positions
      </Heading>

      {loading ? (
        <Box textAlign="center" mt={4} mb={4}>
          <Spinner size="lg" />
        </Box>
      ) : error ? (
        <Alert status="error" w={250} borderRadius={5} mb={4}>
          <AlertIcon />
          <AlertDescription w={200}>{error}</AlertDescription>
        </Alert>
      ) : listings.length === 0 ? (
        <Text mb={4} size="2xl">
          No job listings found.
        </Text>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
          {listings.map((listing) => {
            return (
              <GridItem colSpan={colSpan} key={listing.id}>
                <Job listing={listing} />
              </GridItem>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}

type JobProps = {
  listing: JobListing
}

const Job = ({listing}: JobProps) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
    >
      <Box p="6">
        <Flex justifyContent="space-between" alignContent="center">
          <Box fontSize="2xl" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated={true}>
            {listing.name}
          </Box>
        </Flex>

        <Text fontSize="lg" color={useColorModeValue('gray.500', 'whiteAlpha.600')}>
          {listing.compensation}
        </Text>

        <Flex
          justifyContent="space-between"
          direction="row"
          mb={2}
          color={useColorModeValue('gray.500', 'whiteAlpha.600')}
        >
          <Text fontSize="lg">{listing.duration}</Text>
          <Text fontSize="lg" textAlign="right">
            {listing.hours}
          </Text>
        </Flex>

        <ReactMarkdown components={transformComponents} children={listing.description} />

        <Flex
          justifyContent={useBreakpointValue({base: 'center', sm: 'flex-end'})}
          alignItems="center"
          flexWrap="wrap"
          mb={-2}
        >
          <Text size="md" mr={2} fontWeight={600} my={2}>
            Interested?
          </Text>
          <Button
            as="a"
            href="mailto:jobs@pyr.sh"
            rightIcon={<ChevronRightIcon />}
            size="md"
            _hover={{
              backgroundColor: 'whiteAlpha.300',
              textDecoration: 'none',
            }}
            my={2}
          >
            Send an email to jobs@pyr.sh
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default Home
