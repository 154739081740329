import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {User} from '@supabase/supabase-js'
import {supabase} from '@/api'
import {store, RootState} from '@/store'
import {User as UserProfile} from '@/api/models'

export interface State {
  profile?: UserProfile
  profileLoading: boolean
  user?: User
}

const initialState: State = {
  profileLoading: true,
  user: supabase.auth.session()?.user ?? undefined,
}

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (user: User | undefined, {dispatch, getState}) => {
    dispatch(setUser(user))
    if (!user) {
      return
    }

    const loading = (getState() as RootState).auth.profileLoading
    if (!loading) {
      dispatch(setProfileLoading(true))
    }

    const thisUser = await supabase
      .from<UserProfile>('users')
      .select('*')
      .filter('id', 'eq', user.id)
      .single()
    return thisUser.body
  }
)

export const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.profileLoading = false
      state.profile = action.payload ?? undefined
    })
    builder.addCase(updateUser.rejected, (state) => {
      state.profileLoading = false
      state.profile = undefined
    })
  },
  initialState,
  name: 'auth',
  reducers: {
    setProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.profileLoading = action.payload
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload
    },
  },
})

export const {setProfileLoading, setUser} = authSlice.actions

export const selectUser = (state: RootState) => state.auth.user
export const selectProfileLoading = (state: RootState) => state.auth.profileLoading
export const selectRole = (state: RootState) => state.auth.profile?.role ?? 'user'

supabase.auth.onAuthStateChange(async (_, session) => {
  store.dispatch(updateUser(session?.user ?? undefined))
})

export default authSlice.reducer
