import * as React from 'react'
import {Container, Link, Text, useColorModeValue} from '@chakra-ui/react'

const Footer = () => {
  return (
    <Container maxW="container.xl" mb={8} px={8}>
      <Text textAlign="right" fontSize="sm" color={useColorModeValue('gray.500', 'gray.400')}>
        &copy; 2021 <Link href="https://pyr.sh">pyr.sh</Link>. All rights reserved.
        <br />
      </Text>
    </Container>
  )
}

export default Footer
