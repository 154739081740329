import * as React from 'react'
import * as THREE from 'three'
import {Stage, useGLTF} from '@react-three/drei'
import {Canvas, useFrame} from '@react-three/fiber'
import {GLTF} from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh
  }
  materials: {}
}

const Model = (props: JSX.IntrinsicElements['group']) => {
  const group = React.useRef<THREE.Group>(null!)
  const {nodes, materials} = useGLTF('/potato/potato.gltf') as GLTFResult

  useFrame((state, delta) => {
    group.current.rotation.y += 0.01
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow={true}
        receiveShadow={true}
        geometry={nodes.mesh_0.geometry}
        material={nodes.mesh_0.material}
      />
    </group>
  )
}

const Potato = () => {
  return (
    <Canvas shadows={true} dpr={[1, 2]} camera={{fov: 50}}>
      <React.Suspense fallback={null}>
        <Stage preset="soft" intensity={2} environment="lobby">
          <Model position={[0, 0, 0]} scale={[1.2, 1.2, 1.2]} />
        </Stage>
      </React.Suspense>
    </Canvas>
  )
}

export default Potato

useGLTF.preload('/potato/potato.gltf')
