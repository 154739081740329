import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Container,
  Divider,
  Grid,
  GridItem,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {useParams} from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Countdown from 'react-countdown'
import * as date from 'date-fns'
import * as tzdate from 'date-fns-tz'
import {useDropzone} from 'react-dropzone'

import {supabase} from '@/api'
import {transformComponents} from '@/common/markdown'
import {QuizLookup, QuizStartResult, QuizCompleteResult} from '@/api/models'

type Params = {
  id: string
}

const ViewQuiz = () => {
  const [quizLookup, setQuizLookup] = React.useState<QuizLookup | null>(null)
  const {id} = useParams<Params>()

  const headerColor = useColorModeValue('gray.400', 'gray.500')
  const dropperBackground = useColorModeValue('gray.100', 'gray.700')

  const loadQuiz = React.useCallback(async () => {
    setQuizLookup(null)
    const quiz = await supabase.rpc<QuizLookup>('lookup_quiz_entry', {entry_id: id}).select('*').single()
    setQuizLookup(quiz.data)
  }, [id])

  React.useEffect(() => {
    loadQuiz()
  }, [loadQuiz])

  const handleBegin = React.useCallback(async () => {
    const result = await supabase
      .rpc<QuizStartResult>('start_quiz_attempt', {entry_id: id})
      .select('*')
      .single()
    setQuizLookup({
      ...quizLookup,
      ...result.data,
    } as QuizLookup)
  }, [id, quizLookup])

  const finishesAt = React.useMemo(() => {
    if (!quizLookup?.started_at) {
      return undefined
    }

    const startedAt = tzdate.zonedTimeToUtc(quizLookup.started_at, 'yyyy-MM-ddTHH:mm:ss.SSSSSS')
    const maxTime = date.parse(quizLookup.max_time, 'kk:mm:ss', startedAt)
    return date.add(startedAt, {
      hours: maxTime.getHours(),
      minutes: maxTime.getMinutes(),
      seconds: maxTime.getSeconds(),
    })
  }, [quizLookup])

  const [loading, setLoading] = React.useState<boolean>(false)
  const [file, setFile] = React.useState<File | null>(null)
  const [error, setError] = React.useState<string | null>(null)
  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return
      }
      setFile(acceptedFiles[0])
    },
    [setFile]
  )
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: [
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
    ],
    maxFiles: 1,
    onDrop,
  })
  const handleSubmit = React.useCallback(async () => {
    if (!file) {
      return
    }

    try {
      setLoading(true)
      setError(null)

      const nameParts = file.name.split('.')
      const ext = nameParts.pop()
      const path = `${nameParts.join('.')}-${Math.random()}.${ext}`

      const {error} = await supabase.storage.from('quiz-submissions').upload(path, file)
      if (error) {
        throw error
      }

      const result = await supabase
        .rpc<QuizCompleteResult>('complete_quiz_attempt', {entry_id: id, filename: path})
        .select('*')
        .single()
      setQuizLookup({
        ...quizLookup,
        ...result.data,
      } as QuizLookup)
    } catch (e: any) {
      setError(e.toString())
    } finally {
      setLoading(false)
    }
  }, [file, setError, setLoading, id, quizLookup])

  const timeElapsed = React.useMemo(() => {
    if (!quizLookup || !quizLookup.started_at || !quizLookup.completed_at) {
      return null
    }
    const duration = date.intervalToDuration({
      end: date.parseISO(quizLookup.completed_at),
      start: date.parseISO(quizLookup.started_at),
    })
    return `${((duration.days ?? 0) * 24 + (duration.hours ?? 0)).toString().padStart(2, '0')}:${(
      duration.minutes?.toString() ?? '0'
    ).padStart(2, '0')}:${(duration.seconds?.toString() ?? '0').padStart(2, '0')}`
  }, [quizLookup])

  if (!quizLookup) {
    return (
      <Container maxWidth="container.xl" textAlign="center">
        <Spinner size="xl" mt={8} mb={8} />
      </Container>
    )
  }

  return (
    <Container maxWidth="container.xl" px={8}>
      <Stack flex={1} justify="space-between" direction="row" spacing={2} mb={4}>
        <Heading size="lg">Hey {quizLookup.name}!</Heading>
        <Heading size="lg" color={headerColor}>
          {quizLookup.title}
        </Heading>
      </Stack>

      <Grid gap={4} templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={3}>
          <ReactMarkdown components={transformComponents} children={quizLookup.introduction} />
        </GridItem>
        <GridItem
          colSpan={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          mb={4}
        >
          {quizLookup.completed_at ? (
            <>
              <Text mb={4} fontSize="xl">
                Completed in
              </Text>
              <Text mb={4} fontSize="2xl" fontWeight={700}>
                {timeElapsed}
              </Text>
              <Text mb={4} fontSize="xl">
                Thank you!
              </Text>
            </>
          ) : !quizLookup.started_at ? (
            <>
              <Text mb={4} fontSize="2xl" fontWeight={700}>
                {quizLookup.max_time} left
              </Text>
              <Button colorScheme="green" size="lg" onClick={handleBegin}>
                Begin
              </Button>
            </>
          ) : (
            <>
              <Text mb={4} fontSize="2xl" fontWeight={700}>
                <Countdown daysInHours={true} date={finishesAt} /> left
              </Text>
              <Button colorScheme="green" size="lg" disabled={true}>
                Begin
              </Button>
            </>
          )}
        </GridItem>
      </Grid>

      {!!quizLookup?.instructions && (
        <>
          <Divider mb={4} />

          <Grid gap={4} templateColumns="repeat(4, 1fr)">
            <GridItem colSpan={3}>
              <ReactMarkdown components={transformComponents} children={quizLookup.instructions} />
            </GridItem>
            <GridItem
              colSpan={1}
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
              mb={4}
            >
              {!quizLookup.completed_at && (
                <>
                  <Flex
                    bg={dropperBackground}
                    w={0.85}
                    h={40}
                    justify="center"
                    align="center"
                    p={5}
                    mx={5}
                    mb={4}
                    borderRadius={5}
                    textAlign="center"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {file ? (
                      <Text>{file.name}</Text>
                    ) : isDragActive ? (
                      <Text>Drop the files here...</Text>
                    ) : (
                      <Text>Drag and drop a ZIP file with your solution here</Text>
                    )}
                  </Flex>
                  {!!error && (
                    <Alert status="error" w={250} borderRadius={5} mb={4}>
                      <AlertIcon />
                      <AlertDescription w={200}>{error}</AlertDescription>
                    </Alert>
                  )}
                  <Button
                    colorScheme="green"
                    size="lg"
                    disabled={!file || loading}
                    isLoading={loading}
                    loadingText="Uploading..."
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </>
              )}
            </GridItem>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default ViewQuiz
