import * as React from 'react'
import {Route, RouteProps, Redirect} from 'react-router-dom'
import {Flex, Spinner} from '@chakra-ui/react'

import {useAppSelector} from '@/store'
import {selectProfileLoading, selectRole, selectUser} from '@/auth/state'

type ProtectedProps = {
  adminRequired?: boolean
}

export const ProtectedRoute = (props: ProtectedProps & RouteProps) => {
  const {loading, role, user} = useAppSelector((state) => ({
    loading: selectProfileLoading(state),
    role: selectRole(state),
    user: selectUser(state),
  }))

  if (!user) {
    return <Route {...props} component={() => <Redirect to={{pathname: '/signin'}} />} />
  }

  if (props.adminRequired) {
    if (loading) {
      return <Flex
        alignItems='center'
        justifyContent='center'
        mt={8}
        mb={8}
      >
        <Spinner size='lg' />
      </Flex>
    }
    if (role !== 'admin') {
      return <Redirect to={{pathname: '/signin'}} />
    }
  }

  return <Route {...props} />
}

export const LoggedOutRoute = (props: RouteProps) => {
  const user = useAppSelector(selectUser)

  const isLoggedIn = !!user
  if (isLoggedIn) {
    return <Route {...props} component={() => <Redirect to={{pathname: '/'}} />} />
  }
  return <Route {...props} />
}
