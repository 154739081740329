import * as React from 'react'
import {Text, Stack, Image, Link, useColorModeValue, useBreakpointValue} from '@chakra-ui/react'

import {Link as RouterLink} from 'react-router-dom'

import logo from './logo.svg'

const Logo = () => {
  return (
    <Link as={RouterLink} to="/" _hover={{textDecoration: 'none'}}>
      <Stack flex={{base: 1, md: 0}} justify="center" direction="row" fontSize="xl" spacing={0}>
        <Image src={logo} alt="Logo" boxSize="32px" />
        <Text
          textAlign={useBreakpointValue({base: 'center', md: 'left'})}
          fontFamily="Roboto Mono"
          fontWeight="700"
          letterSpacing={-1}
          pl={0.5}
          color={useColorModeValue('gray.800', 'white')}
        >
          ./pyr.sh
        </Text>
        {/* <Text
          textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
          fontFamily='Roboto Mono'
          fontWeight='700'
          letterSpacing={-1}
          pl={2}
          color='#d99e82'
        >
          jobs
        </Text> */}
      </Stack>
    </Link>
  )
}

export default Logo
