import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import {
  AddIcon,
  ArrowForwardIcon,
  CloseIcon,
  EditIcon,
} from '@chakra-ui/icons'
import {Link} from 'react-router-dom'

import {supabase} from '@/api'
import {Quiz} from '@/api/models'

type EditMode = 'add' | 'edit' | 'delete'

const Quizzes = () => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [quizzes, setQuizzes] = React.useState<Quiz[]>([])
  const loadQuizzes = React.useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const { data, error } = await supabase
        .from<Quiz>('quizzes')
        .select('*')
        .order('title', { ascending: true })
      if (error) {
        throw error
      }
      setQuizzes(data || [])
    } catch (e) {
      setError(e.details || e.toString())
    } finally {
      setLoading(false)
    }
  }, [setLoading])
  React.useEffect(() => { loadQuizzes() }, [loadQuizzes])

  const [editMode, setEditMode] = React.useState<EditMode>('add')
  const [editedModel, setEditedModel] = React.useState<Quiz | undefined>(undefined)
  const [modalError, setModalError] = React.useState<string | undefined>(undefined)
  const [modalLoading, setModalLoading] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const handleEdit = React.useCallback((mode: EditMode, quiz: Quiz | undefined) => {
    setModalError(undefined)
    setEditMode(mode)
    setEditedModel(quiz)
    onOpen()
  }, [setEditMode, setEditedModel, onOpen, setModalError])
  const handleConfirm = React.useCallback(async (quiz: Quiz | undefined) => {
    if (!quiz) {
      return
    }

    setModalLoading(true)
    setModalError(undefined)

    try {
      if (editMode === 'delete') {
        const {error} = await supabase
          .from('quizzes')
          .delete()
          .match({ id: quiz.id })
        if (error) {
          throw error
        }
      } else if (editMode === 'add') {
        const {error} = await supabase
          .from<Quiz>('quizzes')
          .insert(quiz)
        if (error) {
          throw error
        }
      } else if (editMode === 'edit') {
        const {error} = await supabase
          .from<Quiz>('quizzes')
          .update(quiz)
          .match({ id: quiz.id })
        if (error) {
          throw error
        }
      }

      onClose()
      loadQuizzes()
    } catch (e) {
      setModalError(e?.details || e?.message || e.toString())
    } finally {
      setModalLoading(false)
    }
  }, [editMode, onClose, setModalError, setModalLoading, loadQuizzes])
  const handleCreate = React.useCallback(() => handleEdit('add', undefined), [handleEdit])

  return (
    <Container maxW='container.xl' px={8} mb={8}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        mb={4}
      >
        <Heading size='lg'>Quizzes</Heading>
        <Button
          leftIcon={<AddIcon />}
          onClick={handleCreate}
        >Create</Button>
      </Flex>
      { loading
        ? <Box textAlign='center' mt={4} mb={4}>
            <Spinner size='lg' />
          </Box>
        : error
        ? <Alert
            status='error'
            borderRadius={5}
            mb={4}
          >
            <AlertIcon />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        : quizzes.length === 0
        ? <Text>No quizzes found</Text>
        : quizzes.map(quiz => (
          <QuizRow
            key={quiz.id}
            quiz={quiz}
            onEdit={handleEdit}
          />
        ))}

        <Editor
          isOpen={isOpen}
          onClose={onClose}
          mode={editMode}
          model={editedModel}
          onConfirm={handleConfirm}
          error={modalError}
          loading={modalLoading}
        />
    </Container>
  )
}

type QuizRowProps = {
  quiz: Quiz
  onEdit: (mode: EditMode, quiz: Quiz) => void
}

const QuizRow = ({ quiz, onEdit }: QuizRowProps) => {
  const handleEdit = React.useCallback(() => onEdit('edit', quiz), [quiz, onEdit])
  const handleDelete = React.useCallback(() => onEdit('delete', quiz), [quiz, onEdit])

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
      mb={4}
    >
      <Box p="6">
        <Flex justifyContent="space-between" alignContent="center" direction="row">
          <Box
            fontSize="xl"
            fontWeight="semibold"
            as="h4"
            isTruncated={true}
          >
            {quiz.title}
          </Box>
          <Stack direction="row" spacing={4}>
            <Tooltip label="View all entries">
              <IconButton
                as={Link}
                to={`/admin/quizzes/${quiz.id}`}
                icon={<ArrowForwardIcon />}
                aria-label='View all entries'
              />
            </Tooltip>
            <Tooltip label="Edit the quiz">
              <IconButton
                icon={<EditIcon />}
                aria-label='Edit the quiz'
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip label="Delete the quiz">
              <IconButton
                icon={<CloseIcon />}
                aria-label='Delete the quiz'
                onClick={handleDelete}
              />
            </Tooltip>
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}

type EditorProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (listing: Quiz | undefined) => void
  mode: EditMode
  model?: Quiz
  loading: boolean
  error?: string
}

const Editor = ({isOpen, onClose, onConfirm, mode, model, loading, error}: EditorProps) => {
  const [draft, setDraft] = React.useState<Quiz | undefined>(model)
  React.useEffect(() => setDraft(model), [model])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Creating a new quiz'
            : mode === 'edit'
            ? `Editing ${model?.title}`
            : 'Confirm the deletion'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!error && (
            <Alert
              status='error'
              borderRadius={5}
              mb={4}
            >
              <AlertIcon />
              <AlertDescription w={200}>{error}</AlertDescription>
            </Alert>
          )}

          {mode === 'delete'
            ? `Please confirm the deletion of ${model?.title}.`
            : <Form model={draft} setModel={setDraft} />}
        </ModalBody>

        <ModalFooter>
          { mode === 'delete'
            ? <Button isLoading={loading} colorScheme="red" mr={3} onClick={() => onConfirm(draft)}>
              Delete
            </Button>
            : <Button isLoading={loading} colorScheme="blue" mr={3} onClick={() => onConfirm(draft)}>
              Save
            </Button>}
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type FormProps = {
  model?: Quiz
  setModel: (model: Quiz | undefined) => void
}

const Form = ({model, setModel}: FormProps) => {
  return (
    <Stack>
      <FormControl id="name">
        <FormLabel>Title</FormLabel>
        <Input
          type="text"
          value={model?.title || ''}
          onChange={(e) => setModel({
            ...(model || {}),
            title: e.target.value,
          } as Quiz)}
        />
      </FormControl>
      <FormControl id="order">
        <FormLabel>Max time (hh:mm:ss)</FormLabel>
        <Input
          type="text"
          value={model?.max_time || '00:00:00'}
          onChange={(e) => setModel({
            ...(model || {}),
            max_time: e.target.value,
          } as Quiz)}
        />
      </FormControl>
      <FormControl id="compensation">
        <FormLabel>Introduction</FormLabel>
        <Textarea
          type="text"
          value={model?.introduction || ''}
          onChange={(e) => setModel({
            ...(model || {}),
            introduction: e.target.value,
          } as Quiz)}
          rows={15}
        />
      </FormControl>
      <FormControl id="duration">
        <FormLabel>Instructions</FormLabel>
        <Textarea
          type="text"
          value={model?.instructions || ''}
          onChange={(e) => setModel({
            ...(model || {}),
            instructions: e.target.value,
          } as Quiz)}
          rows={15}
        />
      </FormControl>
    </Stack>
  )
}

export default Quizzes
