import * as React from 'react'
import {
  Avatar,
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {HamburgerIcon, CloseIcon, MoonIcon, SunIcon} from '@chakra-ui/icons'
import {User} from '@supabase/supabase-js'
import {NavLink as RouterNavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

import {supabase} from '@/api'
import {useAppSelector} from '@/store'
import {selectRole, selectUser} from '@/auth/state'
import Logo from './logo'

const Header = () => {
  const {isOpen, onClose, onToggle} = useDisclosure()
  const btnRef = React.useRef<HTMLButtonElement>(null)

  const {colorMode, toggleColorMode} = useColorMode()

  const user = useAppSelector(selectUser)
  const handleSignIn = React.useCallback(() => {
    supabase.auth.signIn({provider: 'github'})
  }, [])

  return (
    <Container maxW="container.xl" mt={4} mb={4}>
      <Flex
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{base: 2}}
        px={{base: 4}}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{base: 1, md: 'auto'}} ml={{base: -2}} display={{base: 'flex', md: 'none'}}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
            ref={btnRef}
          />
        </Flex>

        <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}}>
          <Logo />

          <Flex display={{base: 'none', md: 'flex'}} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack flex={{base: 1, md: 0}} justify="flex-end" direction="row" spacing={2}>
          {colorMode === 'light' ? (
            <IconButton
              aria-label="Switch to dark mode"
              icon={<MoonIcon />}
              onClick={toggleColorMode}
              bg="none"
            />
          ) : (
            <IconButton
              aria-label="Switch to light mode"
              icon={<SunIcon />}
              onClick={toggleColorMode}
              bg="none"
            />
          )}
          {user ? (
            <UserMenu user={user} />
          ) : (
            <IconButton
              aria-label="Sign in"
              icon={<FontAwesomeIcon icon={faSignInAlt} />}
              onClick={handleSignIn}
              bg="none"
            />
          )}
        </Stack>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <MobileNav onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.500', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const linkActiveColor = useColorModeValue('black', 'white')

  return (
    <Stack direction="row" spacing={8} alignItems="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            as={RouterNavLink}
            to={navItem.href}
            mx={2}
            fontWeight={500}
            color={linkColor}
            _hover={{
              color: linkHoverColor,
              textDecoration: 'none',
            }}
            activeStyle={{
              color: linkActiveColor,
              fontWeight: 600,
            }}
            exact={true}
          >
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  )
}

const MobileNav = ({onClose}: {onClose: () => void}) => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{md: 'none'}}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({label, href, onClose}: NavItem & {onClose: () => void}) => {
  return (
    <Stack spacing={4}>
      <Flex
        as={RouterNavLink}
        py={2}
        to={href}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={onClose}
        activeStyle={{
          fontWeight: 600,
        }}
        exact={true}
      >
        <Text color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
      </Flex>
    </Stack>
  )
}

interface NavItem {
  label: string
  href: string
}

const NAV_ITEMS: Array<NavItem> = [
  {
    href: '/',
    label: 'Company',
  },
  {
    href: '/jobs',
    label: 'Jobs',
  },
]

const UserMenu = ({user}: {user: User}) => {
  const role = useAppSelector(selectRole)
  const handleSignOut = React.useCallback(() => {
    supabase.auth.signOut().catch(console.error)
  }, [])

  return (
    <Menu closeOnBlur={true} closeOnSelect={true} placement="bottom-end">
      <MenuButton rounded="full" variant="link" cursor="pointer" minW={0}>
        <Avatar size="sm" src={user.user_metadata.avatar_url} />
      </MenuButton>
      <MenuList>
        {role === 'admin' && (
          <>
            <MenuItem as={RouterNavLink} to="/admin/jobs">
              Job listings
            </MenuItem>
            <MenuItem as={RouterNavLink} to="/admin/quizzes">
              Quizzes
            </MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default Header
