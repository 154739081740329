import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {AddIcon, CloseIcon, DownloadIcon, EditIcon, LinkIcon} from '@chakra-ui/icons'
import {useParams} from 'react-router-dom'
import * as date from 'date-fns'

import {supabase} from '@/api'
import {QuizEntry} from '@/api/models'

type EditMode = 'add' | 'edit' | 'delete'

type Params = {
  id: string
}

const Entries = () => {
  const {id} = useParams<Params>()

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [entries, setEntries] = React.useState<QuizEntry[]>([])
  const loadEntries = React.useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const {data, error} = await supabase
        .from<QuizEntry>('quiz_entries')
        .select('*')
        .filter('quiz_id', 'eq', id)
        .order('created_at', {ascending: false})
      if (error) {
        throw error
      }
      setEntries(data || [])
    } catch (e) {
      setError(e?.details || e?.message || e.toString())
    } finally {
      setLoading(false)
    }
  }, [setLoading, id])
  React.useEffect(() => {
    loadEntries()
  }, [loadEntries])

  const [editMode, setEditMode] = React.useState<EditMode>('add')
  const [editedModel, setEditedModel] = React.useState<QuizEntry | undefined>(undefined)
  const [modalError, setModalError] = React.useState<string | undefined>(undefined)
  const [modalLoading, setModalLoading] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const handleEdit = React.useCallback(
    (mode: EditMode, entry: QuizEntry | undefined) => {
      setEditMode(mode)
      setModalError(undefined)
      if (entry?.quiz_id === undefined) {
        entry = {quiz_id: id} as QuizEntry
      }
      setEditedModel(entry)
      onOpen()
    },
    [setEditMode, setEditedModel, setModalError, onOpen, id]
  )
  const handleConfirm = React.useCallback(
    async (entry: QuizEntry | undefined) => {
      if (!entry) {
        return
      }

      setModalLoading(true)
      setModalError(undefined)

      try {
        if (editMode === 'delete') {
          const {error} = await supabase.from('quiz_entries').delete().match({id: entry.id})
          if (error) {
            throw error
          }
        } else if (editMode === 'add') {
          const {error} = await supabase.from<QuizEntry>('quiz_entries').insert(entry)
          if (error) {
            throw error
          }
        } else if (editMode === 'edit') {
          const {error} = await supabase.from<QuizEntry>('quiz_entries').update(entry).match({id: entry.id})
          if (error) {
            throw error
          }
        }

        onClose()
        loadEntries()
      } catch (e) {
        setModalError(e?.details || e?.message || e.toString())
      } finally {
        setModalLoading(false)
      }
    },
    [editMode, onClose, setModalError, setModalLoading, loadEntries]
  )
  const handleCreate = React.useCallback(() => handleEdit('add', undefined), [handleEdit])

  return (
    <Container maxW="container.xl" px={8} mb={8}>
      <Flex direction="row" justifyContent="space-between" alignContent="center" mb={4}>
        <Heading size="lg">Entries</Heading>
        <Button leftIcon={<AddIcon />} onClick={handleCreate}>
          Create
        </Button>
      </Flex>
      {loading ? (
        <Box textAlign="center" mt={4} mb={4}>
          <Spinner size="lg" />
        </Box>
      ) : error ? (
        <Alert status="error" borderRadius={5} mb={4}>
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : entries.length === 0 ? (
        <Text>No entries found</Text>
      ) : (
        entries.map((entry) => <QuizEntryRow key={entry.id} entry={entry} onEdit={handleEdit} />)
      )}

      <Editor
        isOpen={isOpen}
        onClose={onClose}
        mode={editMode}
        model={editedModel}
        onConfirm={handleConfirm}
        error={modalError}
        loading={modalLoading}
      />
    </Container>
  )
}

type QuizEntryRowProps = {
  entry: QuizEntry
  onEdit: (mode: EditMode, entry: QuizEntry) => void
}

const QuizEntryRow = ({entry, onEdit}: QuizEntryRowProps) => {
  const handleEdit = React.useCallback(() => onEdit('edit', entry), [entry, onEdit])
  const handleDelete = React.useCallback(() => onEdit('delete', entry), [entry, onEdit])
  const handleDownload = React.useCallback(async () => {
    if (!entry.filename) {
      return
    }

    const {signedURL, error} = await supabase.storage
      .from('quiz-submissions')
      .createSignedUrl(entry.filename, 60)
    if (error) {
      alert(JSON.stringify(error))
      return
    }
    if (!signedURL) {
      return
    }
    window.location.href = signedURL
  }, [entry.filename])

  const timeElapsed = React.useMemo(() => {
    if (!entry || !entry.started_at || !entry.completed_at) {
      return null
    }
    return new Date(
      date.differenceInSeconds(date.parseISO(entry.completed_at), date.parseISO(entry.started_at)) * 1000
    )
      .toISOString()
      .substr(11, 8)
  }, [entry])

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
      mb={4}
    >
      <Box p="6">
        <Flex justifyContent="space-between" alignContent="center" direction="row">
          <Stack direction="column" spacing={2}>
            <Flex direction="row" alignItems="center">
              <Box fontSize="xl" fontWeight="semibold" as="h4" mb={0} isTruncated={true}>
                {entry.name} ({entry.email})
              </Box>
            </Flex>
            <Link
              href={`https://pyr.sh/quiz/${entry.id}`}
              size="md"
              display="flex"
              flexDirection="row"
              alignItems="center"
              color={useColorModeValue('grey.400', 'whiteAlpha.500')}
              target="blank"
            >
              <LinkIcon mr={2} />
              <Text>https://pyr.sh/quiz/{entry.id}</Text>
            </Link>
            <Stack direction="row" spacing={2} pt={1}>
              <Button leftIcon={<EditIcon />} onClick={handleEdit} size="sm">
                Edit
              </Button>
              <Button leftIcon={<CloseIcon />} onClick={handleDelete} size="sm">
                Delete
              </Button>
              {!!entry.filename && (
                <Button leftIcon={<DownloadIcon />} onClick={handleDownload} size="sm">
                  Download
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={4} fontSize="sm" textAlign="right">
            <Text>Created on {date.format(new Date(entry.created_at), 'yyyy/MM/dd HH:mm')}</Text>
            {entry.started_at ? (
              <Text>Started on {date.format(new Date(entry.started_at), 'yyyy/MM/dd HH:mm')}</Text>
            ) : (
              <Text>Pending start</Text>
            )}
            {entry.completed_at ? (
              <Text>
                Completed on {date.format(new Date(entry.completed_at), 'yyyy/MM/dd HH:mm')} ({timeElapsed})
              </Text>
            ) : (
              <Text>Pending completion</Text>
            )}
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}

type EditorProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (listing: QuizEntry | undefined) => void
  mode: EditMode
  model?: QuizEntry
  loading: boolean
  error?: string
}

const Editor = ({isOpen, onClose, onConfirm, mode, model, loading, error}: EditorProps) => {
  const [draft, setDraft] = React.useState<QuizEntry | undefined>(model)
  React.useEffect(() => setDraft(model), [model])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Creating a new quiz entry'
            : mode === 'edit'
            ? `Editing ${model?.name}'s quiz entry`
            : 'Confirm the deletion'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!error && (
            <Alert status="error" borderRadius={5} mb={4}>
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {mode === 'delete' ? (
            `Please confirm the deletion of ${model?.name}'s entry.`
          ) : (
            <Form model={draft} setModel={setDraft} />
          )}
        </ModalBody>

        <ModalFooter>
          {mode === 'delete' ? (
            <Button isLoading={loading} colorScheme="red" mr={3} onClick={() => onConfirm(draft)}>
              Delete
            </Button>
          ) : (
            <Button isLoading={loading} colorScheme="blue" mr={3} onClick={() => onConfirm(draft)}>
              Save
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type FormProps = {
  model?: QuizEntry
  setModel: (model: QuizEntry | undefined) => void
}

const Form = ({model, setModel}: FormProps) => {
  return (
    <Stack>
      <FormControl id="name">
        <FormLabel>E-mail address</FormLabel>
        <Input
          type="email"
          value={model?.email || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              email: e.target.value,
            } as QuizEntry)
          }
        />
      </FormControl>
      <FormControl id="order">
        <FormLabel>Entrant's name</FormLabel>
        <Input
          type="text"
          value={model?.name || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              name: e.target.value,
            } as QuizEntry)
          }
        />
      </FormControl>
    </Stack>
  )
}

export default Entries
