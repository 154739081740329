import * as React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {ProtectedRoute, LoggedOutRoute} from '@/router/protect'

export default () => {
  return (
    <Switch>
      {/* Logged out routes auto-redirect to / if you're logged in */}
      {/* <LoggedOutRoute
        path="/signin"
        exact={true}
        component={require('@/auth/signin/form/container').default}
      /> */}
      <Route path="/" exact={true} component={require('@/home').default} />
      <Route path="/jobs" exact={true} component={require('@/jobs').default} />

      {/* Quizzes */}
      <Route path="/quiz/:id" component={require('@/quizzes/view').default} />
      <ProtectedRoute
        adminRequired={true}
        exact={true}
        component={require('@/jobs/list').default}
        path="/admin/jobs"
      />
      <ProtectedRoute
        adminRequired={true}
        exact={true}
        component={require('@/quizzes').default}
        path="/admin/quizzes"
      />
      <ProtectedRoute
        adminRequired={true}
        exact={true}
        component={require('@/quizzes/entries').default}
        path="/admin/quizzes/:id"
      />

      <Route component={() => <Redirect to={{pathname: '/'}} />} />
    </Switch>
  )
}
