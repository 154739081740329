import React from 'react'
import {
  Container,
  Grid,
  GridItem,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartLine,
  faDesktop,
  faServer,
} from '@fortawesome/free-solid-svg-icons'

import logo from '@/header/logo.svg'
import fries from './fries.svg'

import golang from './technologies/golang.svg'
import typescript from './technologies/typescript.svg'
import react from './technologies/react.svg'
import reactNative from './technologies/react-native.svg'
import graphql from './technologies/graphql.svg'
import postgresql from './technologies/postgresql.svg'
import docker from './technologies/docker.svg'
import kubernetes from './technologies/kubernetes.svg'
import temporal from './technologies/temporal.svg'
import temporalWhite from './technologies/temporal-white.svg'
import rabbitmq from './technologies/rabbitmq.svg'
import python from './technologies/python.svg'
import supabase from './technologies/supabase.svg'
import aws from './technologies/aws.svg'
import awsWhite from './technologies/aws-white.svg'
import googleCloud from './technologies/google-cloud.svg'
import digitalOcean from './technologies/digitalocean.svg'
import oracleCloud from './technologies/oracle-cloud.svg'

type TechnologyProps = {
  name: string
  src: string
}

const Technology = ({name, src}: {name: string, src: string}) => {
  const size = useBreakpointValue({base: '32px', lg: '64px'})
  const mb = useBreakpointValue({base: 2, lg: 4})

  return (
    <GridItem
      display="flex"
      flexDirection="row"
      alignItems="center"
      colSpan={1}
      mb={mb}
    >
      <Flex
        height={size}
        width={size}
        alignItems="center"
        justifyContent="center"
      >
        <Image src={src} maxWidth={size} maxHeight={size} width='100%' />
      </Flex>
      <Text ml={4} fontSize="2xl" color="gray.6s00">{name}</Text>
    </GridItem>
  )
}

const Home = () => {
  const iconColor = useColorModeValue('gray.600', 'whiteAlpha.700')
  const awsLogo = useColorModeValue(aws, awsWhite)
  const temporalLogo = useColorModeValue(temporal, temporalWhite)

  const technologies: TechnologyProps[] = React.useMemo(() => [
    {
      name: 'Golang',
      src: golang,
    },
    {
      name: 'TypeScript',
      src: typescript,
    },
    {
      name: 'React.js',
      src: react,
    },
    {
      name: 'React Native',
      src: reactNative,
    },
    {
      name: 'GraphQL',
      src: graphql,
    },
    {
      name: 'PostgreSQL',
      src: postgresql,
    },
    {
      name: 'Docker',
      src: docker,
    },
    {
      name: 'Kubernetes',
      src: kubernetes,
    },
    {
      name: 'Temporal',
      src: temporalLogo,
    },
    {
      name: 'RabbitMQ',
      src: rabbitmq,
    },
    {
      name: 'Python',
      src: python,
    },
    {
      name: 'Supabase',
      src: supabase,
    },
    {
      name: 'AWS',
      src: awsLogo,
    },
    {
      name: 'Google Cloud',
      src: googleCloud,
    },
    {
      name: 'DigitalOcean',
      src: digitalOcean,
    },
    {
      name: 'Oracle Cloud',
      src: oracleCloud,
    },
  ], [awsLogo, temporalLogo])

  return (
    <Container maxW="container.xl" mb={8} px={8}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={16}>
        <GridItem
          colSpan={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="4xl" mb={2}>
            Build your product with us!
          </Text>
          <Text fontSize="xl" mb={2}>
            We're a boutique software engineering agency specialized in efficient development of complex software using the Go programming language.
          </Text>
          <Text fontSize="xl">
            Made with love in 🇵🇱 Poland.
          </Text>
        </GridItem>
        <GridItem
          colSpan={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={logo} maxHeight="200px" />
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={16}>
        <GridItem
          colSpan={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={fries} maxHeight="200px" />
        </GridItem>
        <GridItem
          colSpan={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="2xl" mb={2}>
            Tailored to your needs
          </Text>
          <Text fontSize="xl">
            We deliver end-to-end solutions, architected from scratch - from an MVP to a production-ready platform. We adapt solutions to your budget, timelines and expectations - from simple websites to powerful platforms that power your business.
          </Text>
        </GridItem>
      </Grid>

      <Text fontSize="2xl" mb={8} textAlign="center">
        Have our experts handle your problems!
      </Text>

      <Grid
        templateColumns={useBreakpointValue({base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'})}
        gap={4}
        mb={16}
        textAlign="center"
      >
        <GridItem
          colSpan={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text textAlign="center" fontSize="8xl" color={iconColor}>
            <FontAwesomeIcon icon={faChartLine} />
          </Text>
          <Text>
            We have experience building out large backend systems, ingesting and analyzing many terabytes of data per day.
          </Text>
        </GridItem>
        <GridItem
          colSpan={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text textAlign="center" fontSize="8xl" color={iconColor}>
            <FontAwesomeIcon icon={faServer} />
          </Text>
          <Text>
            Our DevOps pipelines allow our customers to save money on their products' upkeep, automatically adapting to handle massive workloads.
          </Text>
        </GridItem>
        <GridItem
          colSpan={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text textAlign="center" fontSize="8xl" color={iconColor}>
            <FontAwesomeIcon icon={faDesktop} />
          </Text>
          <Text>
            We've built complex analytics platforms with complicated frontends, tailored to our customers' use cases.
          </Text>
        </GridItem>
      </Grid>

      <Text fontSize="2xl" textAlign="center" mb={8}>We specialize in...</Text>

      <Grid
        templateColumns={useBreakpointValue({base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)'})}
        gap={4} mb={12}
      >
        {technologies.map(technology => (<Technology
          key={technology.name}
          src={technology.src}
          name={technology.name}
        />))}
      </Grid>

      <Text fontSize="2xl" textAlign="center" mb={8}>Hire us!</Text>

      <Flex justifyContent="center">
        <Text fontSize="xl" maxWidth={600}>
          We're usually available with a few weeks of lead time.
          Interested in a cost estimate for your project?
          Send an email with all the details to <Link href="mailto:piotr@pyr.sh">piotr@pyr.sh</Link>.
        </Text>
      </Flex>
    </Container>
  )
}

export default Home
