import * as React from 'react'
import {TransformOptions} from 'react-markdown'
import {
  Link,
  ListItem,
  OrderedList,
  Text,
  StylesProvider,
  UnorderedList,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import {ExternalLinkIcon} from '@chakra-ui/icons'

const WrappedListItem = ({children}: {children: React.ReactNode}) => {
  const styles = useMultiStyleConfig('ListItem', {})

  return (
    <StylesProvider value={styles}>
      <ListItem mb={2} ml={8}>
        {children}
      </ListItem>
    </StylesProvider>
  )
}

export const transformComponents: TransformOptions['components'] = {
  a: ({href, children}) => (
    <Link href={href} color="blue.500" isExternal={true}>
      {children}&nbsp;
      <ExternalLinkIcon mx="2px" />
    </Link>
  ),
  li: ({children}) => <WrappedListItem>{children}</WrappedListItem>,
  ol: ({children}) => <OrderedList mb={4}>{children}</OrderedList>,
  p: ({children}) => (
    <Text mb={4} fontSize="md">
      {children}
    </Text>
  ),
  ul: ({children}) => <UnorderedList mb={4}>{children}</UnorderedList>,
}
