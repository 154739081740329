import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {AddIcon, CloseIcon, EditIcon} from '@chakra-ui/icons'

import {supabase} from '@/api'
import {JobListing} from '@/api/models'

type EditMode = 'add' | 'edit' | 'delete'

const Jobs = () => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [listings, setListings] = React.useState<JobListing[]>([])
  const loadJobs = React.useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const {data, error} = await supabase
        .from<JobListing>('job_listings')
        .select('*')
        .order('order', {ascending: true})
      if (error) {
        throw error
      }
      setListings(data || [])
    } catch (e) {
      setError(e.details || e.toString())
    } finally {
      setLoading(false)
    }
  }, [setLoading])
  React.useEffect(() => {
    loadJobs()
  }, [loadJobs])

  const [editMode, setEditMode] = React.useState<EditMode>('add')
  const [editedModel, setEditedModel] = React.useState<JobListing | undefined>(undefined)
  const [modalError, setModalError] = React.useState<string | undefined>(undefined)
  const [modalLoading, setModalLoading] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const handleEdit = React.useCallback(
    (mode: EditMode, listing: JobListing | undefined) => {
      setEditMode(mode)
      setEditedModel(listing)
      onOpen()
    },
    [setEditMode, setEditedModel, onOpen]
  )
  const handleConfirm = React.useCallback(
    async (listing: JobListing | undefined) => {
      if (!listing) {
        return
      }

      setModalLoading(true)
      setModalError(undefined)

      try {
        if (editMode === 'delete') {
          const {error} = await supabase.from('job_listings').delete().match({id: listing.id})
          if (error) {
            throw error
          }
        } else if (editMode === 'add') {
          const {error} = await supabase.from<JobListing>('job_listings').insert(listing)
          if (error) {
            throw error
          }
        } else if (editMode === 'edit') {
          const {error} = await supabase
            .from<JobListing>('job_listings')
            .update(listing)
            .match({id: listing.id})
          if (error) {
            throw error
          }
        }

        onClose()
        loadJobs()
      } catch (e) {
        setModalError(e?.details || e.toString())
      } finally {
        setModalLoading(false)
      }
    },
    [editMode, onClose, setModalError, setModalLoading, loadJobs]
  )
  const handleCreate = React.useCallback(() => handleEdit('add', undefined), [handleEdit])

  return (
    <Container maxW="container.xl" px={8} mb={8}>
      <Flex direction="row" justifyContent="space-between" alignContent="center" mb={4}>
        <Heading size="lg">Job listings</Heading>
        <Button leftIcon={<AddIcon />} onClick={handleCreate}>
          Create
        </Button>
      </Flex>
      {loading ? (
        <Box textAlign="center" mt={4} mb={4}>
          <Spinner size="lg" />
        </Box>
      ) : error ? (
        <Alert status="error" w={250} borderRadius={5} mb={4}>
          <AlertIcon />
          <AlertDescription w={200}>{error}</AlertDescription>
        </Alert>
      ) : listings.length === 0 ? (
        <Text>No job listings found</Text>
      ) : (
        listings.map((listing) => <Listing key={listing.id} listing={listing} onEdit={handleEdit} />)
      )}

      <Editor
        isOpen={isOpen}
        onClose={onClose}
        mode={editMode}
        model={editedModel}
        onConfirm={handleConfirm}
        error={modalError}
        loading={modalLoading}
      />
    </Container>
  )
}

type ListingProps = {
  listing: JobListing
  onEdit: (mode: EditMode, listing: JobListing) => void
}

const Listing = ({listing, onEdit}: ListingProps) => {
  const handleEdit = React.useCallback(() => onEdit('edit', listing), [listing, onEdit])
  const handleDelete = React.useCallback(() => onEdit('delete', listing), [listing, onEdit])

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
      mb={4}
    >
      <Box p="6">
        <Flex justifyContent="space-between" alignContent="center" direction="row">
          <Box fontSize="xl" fontWeight="semibold" as="h4" isTruncated={true}>
            {listing.name}
          </Box>
          <Stack direction="row" spacing={4}>
            <IconButton icon={<EditIcon />} aria-label="Edit the listing" onClick={handleEdit} />
            <IconButton icon={<CloseIcon />} aria-label="Delete the listing" onClick={handleDelete} />
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}

type EditorProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (listing: JobListing | undefined) => void
  mode: EditMode
  model?: JobListing
  loading: boolean
  error?: string
}

const Editor = ({isOpen, onClose, onConfirm, mode, model, loading, error}: EditorProps) => {
  const [draft, setDraft] = React.useState<JobListing | undefined>(model)
  React.useEffect(() => setDraft(model), [model])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Creating a new job listing'
            : mode === 'edit'
            ? `Editing ${model?.name}`
            : 'Confirm the deletion'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!error && (
            <Alert status="error" borderRadius={5} mb={4}>
              <AlertIcon />
              <AlertDescription w={200}>{error}</AlertDescription>
            </Alert>
          )}

          {mode === 'delete' ? (
            `Please confirm the deletion of ${model?.name}.`
          ) : (
            <Form model={draft} setModel={setDraft} />
          )}
        </ModalBody>

        <ModalFooter>
          {mode === 'delete' ? (
            <Button isLoading={loading} colorScheme="red" mr={3} onClick={() => onConfirm(draft)}>
              Delete
            </Button>
          ) : (
            <Button isLoading={loading} colorScheme="blue" mr={3} onClick={() => onConfirm(draft)}>
              Save
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type FormProps = {
  model?: JobListing
  setModel: (model: JobListing | undefined) => void
}

const Form = ({model, setModel}: FormProps) => {
  return (
    <Stack>
      <FormControl id="name">
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          value={model?.name || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              name: e.target.value,
            } as JobListing)
          }
        />
      </FormControl>
      <FormControl id="order">
        <FormLabel>Order</FormLabel>
        <Input
          type="number"
          value={model?.order || 0}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              order: parseInt(e.target.value),
            } as JobListing)
          }
        />
      </FormControl>
      <FormControl id="compensation">
        <FormLabel>Compensation</FormLabel>
        <Input
          type="text"
          value={model?.compensation || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              compensation: e.target.value,
            } as JobListing)
          }
        />
      </FormControl>
      <FormControl id="duration">
        <FormLabel>Duration</FormLabel>
        <Input
          type="text"
          value={model?.duration || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              duration: e.target.value,
            } as JobListing)
          }
        />
      </FormControl>
      <FormControl id="hours">
        <FormLabel>Hours</FormLabel>
        <Input
          type="text"
          value={model?.hours || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              hours: e.target.value,
            } as JobListing)
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Description</FormLabel>
        <Textarea
          value={model?.description || ''}
          onChange={(e) =>
            setModel({
              ...(model || {}),
              description: e.target.value,
            } as JobListing)
          }
          rows={20}
        />
      </FormControl>
    </Stack>
  )
}

export default Jobs
